// ? React Js
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// ? Material UI
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// ? Next js
import Image from 'next/image';
import React from 'react';
import { API } from '_helpers';

const Item = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
}));

const AboutUs: React.FC<IProps<IWithCards>> = ({ data }) => {
  return (
    <Box
      sx={{
        py: 12,
      }}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} alignItems="center" justifyContent="center">
            <Stack spacing={4}>
              <Typography component="h1" variant="h2" color="text.primary">
                {data.title}
              </Typography>
              <Stack spacing={2}>
                <Typography
                  color="text.secondary"
                  component="div"
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item container spacing={4} xs={12} md={6}>
            {data.cards.map((el) => (
              <Grid item xs={12} sm={6} key={el._id}>
                <Item variant="outlined" sx={{ p: 4 }}>
                  <Image
                    src={`${API}${el.media.url}`}
                    alt={el.media.alternativeText}
                    width={48}
                    height={48}
                  />
                  <Typography component="h3" variant="h3" sx={{ my: 2 }}>
                    {el.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {el.description}
                  </Typography>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUs;
